export const renaultOfferEnd = (date: Date) => {
  const YYYY = date.getFullYear();
  const DD = date.getDate();
  const Month = date.getMonth() + 4;
  return `${DD}/${Month}/${YYYY}`;
};

export const renaultOfferEndString = (date: Date) => {
  const YYYY = date.getFullYear();
  const Day = dayString(date.getDay());
  const DD = date.getDate();
  const Month = monthString(date.getMonth() + 3);
  return `${Day} ${DD} ${Month} ${YYYY}`;
};

export const compareDates = (dateString1: string, dateString2: string) => {
  const [day1, month1, year1] = dateString1.split("/").map(Number);
  const [day2, month2, year2] = dateString2.split("/").map(Number);

  const date1 = new Date(year1, month1 - 1, day1);
  const date2 = new Date(year2, month2 - 1, day2);

  return +date1 >= +date2;
};

const monthString = (monthId: number) => {
  switch (monthId) {
    case 0:
      return "Janvier";
    case 1:
      return "Février";
    case 2:
      return "Mars";
    case 3:
      return "Avril";
    case 4:
      return "Mai";
    case 5:
      return "Juin";
    case 6:
      return "Juillet";
    case 7:
      return "Août";
    case 8:
      return "Septembre";
    case 9:
      return "Octobre";
    case 10:
      return "Novembre";
    case 11:
      return "Décembre";
    default:
      return "Janvier";
  }
};

const dayString = (dayId: number) => {
  switch (dayId) {
    case 0:
      return "Lundi";
    case 1:
      return "Mardi";
    case 2:
      return "Mercredi";
    case 3:
      return "Jeudi";
    case 4:
      return "Vendredi";
    case 5:
      return "Samedi";
    case 6:
      return "Dimanche";
    default:
      return "Lundi";
  }
};
