import React from "react";
import { RoutesPath } from "@utils/enums/routes.utils";
import { Route, Switch, Redirect } from "react-router";
import Codebox from "./codebox";
import OfferScreen from "./offer/offer.screen";
import RenaultOfferScreen from "./renaultOffer/renaultOffer.screen";
import NullScreen from "./404/null.screen";
import RenaultRegisterScreen from "@screens/unAuth/renaultRegister/renaultRegister.screen";

const Auth = () => {
  return (
    <Switch>
      <Route exact path={`/${RoutesPath.CODEBOX}`}>
        <Codebox />
      </Route>
      <Route exact path={`/${RoutesPath.OFFER}`}>
        <OfferScreen />
      </Route>
      <Route exact path={`/${RoutesPath.NULL}`}>
        <NullScreen />
      </Route>
      <Route exact path={`/${RoutesPath.RENAULTOFFER}`}>
        <RenaultOfferScreen />
      </Route>
      <Route path={`/${RoutesPath.RENAULTREGISTER}`}>
        <RenaultRegisterScreen />
      </Route>
      <Redirect
        to={`/${
          localStorage.getItem("path") === "codebox" ||
          localStorage.getItem("path") === "offer"
            ? localStorage.getItem("path")
            : RoutesPath.OFFER
        }`}
      />
    </Switch>
  );
};

export default Auth;
