export enum RoutesPath {
  DEMO = "demo",
  LOGIN = "login",
  REGISTER = "register",
  CODEBOX = "codebox",
  OFFER = "offer",
  RENAULTOFFER = "renault/offer",
  RENAULTLOGIN = "renault/login",
  RENAULTREGISTER = "renault/register",
  NULL = "null",
}
