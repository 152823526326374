import React, { FormEvent, useState, useEffect } from "react";
import { ReactComponent as KabriolRenaultLogo } from "@assets/images/logo-renault-kabriol.svg";
import { useRenaultRegisterStyles } from "./renaultRegister.style";
import { authQuery, authService } from "@store/auth";
import { LoadingButton } from "@mui/lab";
import { FieldErrors } from "@utils/yup.utils";
import { Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { RoutesPath } from "@utils/enums/routes.utils";
import { isMobile } from "@utils/isMobile.utils";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import MaterialLoginTextfield from "@components/materialTextfield/materialLoginTextfield";
import HeaderUnAuth from "@components/headerUnAuth/headerUnAuth";
import Modal from "@components/modal";

const RenaultRegisterScreen = () => {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useRenaultRegisterStyles();
  const isMobiles = isMobile();
  const [errors, setErrors] = useState<FieldErrors>();
  const history = useHistory();

  useEffect(() => {
    const _loading = authQuery.selectLoading().subscribe(setIsLoading);
    return () => {
      _loading.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("registerSuccess")) {
      setIsRegisterSuccess(true);
    }
    history.push(`/${RoutesPath.RENAULTREGISTER}`);
    localStorage.removeItem("register");
  }, []);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    authService
      .register(name, mail, password, confirmPassword, new Date(date))
      .then(() => {
        if (localStorage.getItem("register") === "registerSuccess") {
          history.push(`/${RoutesPath.RENAULTREGISTER}?registerSuccess=true`);
          window.location.reload();
        }
      });
  };

  return (
    <Grid
      container
      xs={12}
      justifyContent="center"
      className={classes.container}
    >
      <HeaderUnAuth />
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Grid
          item
          container
          flexDirection="column"
          alignContent="center"
          rowSpacing={isMobiles ? 4 : 10}
          className={classes.itemContainer}
        >
          <Grid item style={{ textAlign: "center" }}>
            <Typography
              variant={isMobiles ? "h2" : "h1"}
              fontWeight={700}
              color="primary"
            >
              Inscription
            </Typography>
            <KabriolRenaultLogo
              style={{
                margin: isMobiles ? "10px" : "20px",
                width: isMobiles ? "200px" : "319px",
              }}
            />
            <Typography
              fontSize={isMobiles ? 14 : 16}
              fontWeight={400}
              style={{ width: isMobiles ? "100%" : "60%", margin: "auto" }}
            >
              Créez votre compte Kabriol avec Renault pour bénéficier de {`l'`}
              offre « 3 mois de découverte », accessible depuis votre smartphone
              mais aussi directement dans votre voiture!
            </Typography>
          </Grid>
          <Grid
            item
            container
            flexDirection="column"
            sm={8}
            md={8}
            lg={4}
            xl={3}
            rowSpacing={isMobiles ? 2 : 3}
            className={classes.input}
          >
            <Grid item>
              <MaterialLoginTextfield
                label="Prénom du voyageur"
                type="text"
                required
                error={errors?.requiredString}
                placeholder="Entrez le prénom du voyageur"
                color="primary"
                value={name}
                handleChange={setName}
              />
            </Grid>
            <Grid item>
              <MaterialLoginTextfield
                label="Date de naissance"
                type="date"
                placeholder="Entrez votre date de naissance"
                color="primary"
                value={date}
                handleChange={setDate}
              />
            </Grid>
            <Grid item>
              <MaterialLoginTextfield
                label="Adresse mail"
                type="email"
                required
                error={errors?.email}
                placeholder="Entrez une adresse mail"
                color="primary"
                value={mail}
                handleChange={setMail}
              />
            </Grid>
            <Grid item>
              <MaterialLoginTextfield
                label="Mot de passe"
                required
                error={errors?.password}
                placeholder="Entrez un mot de passe"
                color="primary"
                value={password}
                handleChange={setPassword}
                type={"password"}
              />
            </Grid>
            <Grid item>
              <MaterialLoginTextfield
                label="Confirmation mot de passe"
                required
                error={errors?.password}
                placeholder="Confirmez le mot de passe"
                color="primary"
                value={confirmPassword}
                handleChange={setConfirmPassword}
                type={"password"}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.buttonPosition}>
            <p
              className={classes.specialOffer}
              style={{ width: isMobiles ? "100%" : "60%", margin: "auto" }}
            >
              En vous inscrivant, vous profitez de l offre spéciale Renault: « 3
              mois de découverte » 100% gratuit et sans engagement!
            </p>
          </Grid>
          <Grid item className={classes.buttonPosition}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.buttonContainer}
            >
              {`S'inscrire`}
            </LoadingButton>
            <Grid item className={classes.linkLoginPosition}>
              <Typography variant="h6" fontWeight={400}>
                Vous avez déjà un compte sur Kabriol ?
              </Typography>
              <Link
                to={`/${RoutesPath.RENAULTLOGIN}`}
                className={classes.linkLogin}
              >
                Connectez-vous ici!
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {isRegisterSuccess && (
        <Modal
          title="Validation de votre adresse mail"
          description="Merci de valider l’email de vérification envoyé sur votre adresse mail."
          subDescription="Dans votre voiture Renault : lors de votre connexion, votre offre d'essai de 3 mois gratuits démarrera automatiquement."
          isLogo
          handleClickCloseModal={() => setIsRegisterSuccess(false)}
        >
          <div style={{ marginTop: isMobiles ? "20px" : "30px" }}>
            <Typography
              fontSize={isMobiles ? 14 : 16}
              fontWeight={400}
              style={{ margin: "14px 0px" }}
            >
              Sur votre smartphone : Il vous faut l’activer en cliquant sur le
              bouton ci-dessous.
            </Typography>
            <Grid
              item
              container
              spacing={3}
              flexDirection="column"
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Grid item>
                <Button
                  href={`/${RoutesPath.RENAULTLOGIN}`}
                  variant="contained"
                  color="primary"
                  style={{
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Se connecter
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      )}
    </Grid>
  );
};

export default RenaultRegisterScreen;
