import React from "react";
import { Route, Redirect, Switch } from "react-router";
import { RoutesPath } from "@utils/enums/routes.utils";
import RegisterScreen from "./register/register.screen";
import LoginScreen from "./login";
import RenaultLoginScreen from "./renaultLogin/renaultLogin.screen";
import RenaultRegisterScreen from "./renaultRegister/renaultRegister.screen";

const UnAuth = () => {
  return (
    <Switch>
      <Route path={`/${RoutesPath.LOGIN}`}>
        <LoginScreen />
      </Route>
      <Route path={`/${RoutesPath.REGISTER}`}>
        <RegisterScreen />
      </Route>
      <Route path={`/${RoutesPath.RENAULTLOGIN}`}>
        <RenaultLoginScreen />
      </Route>
      <Route path={`/${RoutesPath.RENAULTREGISTER}`}>
        <RenaultRegisterScreen />
      </Route>
      <Redirect to={`/${RoutesPath.LOGIN}`} />
    </Switch>
  );
};

export default UnAuth;
