import React, { FC, useState, useEffect } from "react";
import UnAuth from "./unAuth/unAuth";
import Auth from "./auth/auth";
import Parse from "parse";
import Splash from "./splash/";
import { useHistory } from "react-router-dom";
import { RoutesPath } from "@utils/enums/routes.utils";

interface RoutesProps {}

const Routes: FC<RoutesProps> = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  const history = useHistory();
  const historyJson = history.location.pathname.slice(1);

  useEffect(() => {
    if (query.get(process.env.REACT_APP_RENAULTCODE!)) {
      localStorage.removeItem("path");
      history.push(`/${RoutesPath.RENAULTREGISTER}`);
    } else if (query.get("codebox")) {
      localStorage.setItem("path", "codebox");
      localStorage.removeItem("renault");
    } else if (query.get("offer")) {
      if (historyJson.includes("currentSubscription")) {
        localStorage.setItem(
          `Parse/TDBYrkHdpp6wTyRqD9v2h7uqlN7I0pRuPyxmMP1m/currentUser`,
          historyJson
        );
      }
      localStorage.setItem("path", "offer");
    }
    async function checkUser() {
      const currentUser = await Parse.User.currentAsync();
      if (
        (currentUser &&
          window.location.pathname === `/${RoutesPath.RENAULTREGISTER}`) ||
        window.location.pathname === `/${RoutesPath.RENAULTLOGIN}`
      ) {
        Parse.User.logOut();
        setIsAuth(false);
      } else if (
        currentUser &&
        localStorage.getItem("register") !== "registerSuccess"
      ) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
      setIsLoading(false);
    }
    checkUser();
  }, []);

  if (isLoading) {
    return <Splash isLoading={isLoading} />;
  }
  return isAuth ? <Auth /> : <UnAuth />;
};

export default Routes;
